<template>
    <div class="buy">
        <van-nav-bar title="提交订单" left-arrow @click-left="$router.back()"/>

        <!-- 不显示地区设置 show-area show-detail为false -->
        <van-address-edit
                ref="address"
                :area-list="areaList"
                :show-area="goodsInfo.productType == 2"
                :show-detail="goodsInfo.productType == 2"
                :area-columns-placeholder="['请选择', '请选择', '请选择']"
                @save="onSave"
        >
            <template>
                <van-field v-model="remark" label="备注" placeholder="请输入备注"/>
            </template>
        </van-address-edit>

        <div class="productinfo" v-if="goodsInfo.productImgs">
            <h2>商品信息</h2>
            <van-card
                    :desc="description"
                    :title="goodsInfo.title"
                    :thumb="goodsInfo.productImgs[0]"
            />
            <div class="price">
                <div class="left">{{ pInfo }}</div>
                <div class="right">￥{{ price }}</div>
            </div>
            <div class="num">
                <div class="left">购买数量</div>
                <div class="right">
                    <van-stepper v-model="numValue" @change="changeNum" :min="1" :max="max"/>
                </div>
            </div>
        </div>
        <div class="xy">
            <van-checkbox v-model="checked">我已阅读<router-link to="/pay-protocol">平台用户服务协议</router-link></van-checkbox>
        </div>

        <div class="tip"><van-icon name="warning-o" style="margin-right: 5px"/>购买即视为认同产品使用规则，无正当理由不可退改，如遇使用问题可咨询客服</div>
        <van-submit-bar tip-icon="warning-o"
                        :loading="submitLoading"
                        :price="total" button-type="primary" button-text="微信支付"
                        @submit="onSubmit"/>

    </div>
</template>

<script>
  // @ is an alias to /src
  // import TopUserBar from "@/components/TopUserBar.vue";

  import {getGoodsInfo} from "@/api/goods";
  import {createOrder} from "@/api/order";
  import {Notify, Toast} from 'vant';
  import {getAreaList} from "@/api/system";
  import {getWxConfig} from "@/api/wx";

  export default {
    name: "buy",
    data () {
      return {
        price: 0,
        pInfo: "1920*1080",
        numValue: 1,
        total: 0,
        checked: true,
        description: '',
        areaList: {},
        searchResult: [],
        remark: '',
        goodsInfo: {},
        loading: false,
        submitLoading: false,
        max: 100,
      }
    },
    methods: {
      onSave(content) {
        const data = {
          productId: this.$store.getters.buy.product,
          specId: this.$store.getters.buy.spec,
          remark: this.remark,
          num: this.numValue,
          siteId: this.$store.getters.city.id,
          buyer: {
            name: content.name,
            phone: content.tel,
            address: content.addressDetail,
            areaCode: content.areaCode,
          }
        };
        if (this.$store.getters.pid > 0) {
          data.shareUId = this.$store.getters.pid
        }
        this.submitLoading = true;
        createOrder(data).then(data => {
          if (data.success = true) {
            const _this = this;
            wx.ready(function () {
              wx.chooseWXPay({
                ...data.data,
                package: data.data.packageValue,
                timestamp: data.data.timeStamp,
                success: res =>  {
                  _this.$router.push('/orderinfo/' + data.data.orderId)
                },
                complete: () => {
                  _this.$router.push('/orderinfo/' + data.data.orderId)
                }
              });
            });
          } else {
            Toast(data.msg)
          }
        }).finally(() => {
          this.submitLoading = false;
        })
      },
      onSubmit () {
        if (this.checked == false) {
          Notify({ type: 'warning', message: ' 请阅读平台服务协议' });
          return;
        };
        this.$refs.address.onSave();
      },
      loadGoodsInfo (id) {
        getGoodsInfo({
          productId: id
        }).then(data => {
          if (data.success == true) {
            this.goodsInfo = data.data;
            console.log(this.$store.getters.buy.spec);
            const index = this.goodsInfo.specs.findIndex(item => item.id == this.$store.getters.buy.spec);
            if (index !== -1) {
              this.price = this.goodsInfo.specs[index].price;
              this.pInfo = this.goodsInfo.specs[index].name;
              this.max = this.goodsInfo.specs[index].stock;
              console.log(this.max);
              this.changeNum()
            }
            this.description = this.goodsInfo.content.description;
          }
        }).finally(() => {
          this.loading = false;
        })
      },
      loadAreaList() {
        this.$store.dispatch('GetAreaList').then(data => {
          this.areaList = data
        })
      },
      async loadWxConfig () {
        const url = window.location.href;
        const res = await getWxConfig(url);
        if (res.success == true) {
          wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: [
              'chooseWXPay',
            ]
          });
        }
      },
      changeNum() {
        console.log(this.numValue, this.max);
        if (this.numValue > this.max) {
          this.numValue = this.max;
        }
        this.total = this.price * this.numValue * 100;
      }
    },
    created () {
      this.loadWxConfig();
      const buy = this.$store.getters.buy;
      if (buy.product <= 0) {
        this.$router.back();
        return;
      }
      this.loadGoodsInfo(buy.product);
      this.loadAreaList();
    },
    components: {},
  };
</script>
<style lang="scss">
    .tip {
        position: fixed;
        bottom: 50px;
        color: #ccc;
        font-size: 12px;
        margin-left: 5px;
        i {
            color: #0790e6
        }
    }

    .buy {
        .van-address-edit__buttons {
            display: none;
        }

        .productinfo {
            margin: 12px;
            padding: 10px;
            background: #fff;

            h2 {
                font-size: 14px;
            }

            .price {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;
                border-top: 1px solid #eee;
                border-bottom: 1px solid #eee;

                .left {
                    font-size: 14px;
                }

                .right {
                    font-size: 14px;
                    color: #D50606;
                }
            }

            .num {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;
                padding: 10px 0 0 0;
            }
        }

        .van-card {
            background: #fff;
        }

        .xy {
            padding: 0 15px;
            font-size: 12px;

            a, a:visited {
                color: #1989fa
            }
        }

        .van-submit-bar__bar {
            padding-right: 0;
        }

        .van-submit-bar__tip {
            background: #f7f8fa;
            color: #999
        }

        .van-submit-bar__text {
            text-align: left;
        }

        .van-submit-bar__button {
            border-radius: 0;
            height: 50px;
            width: 130px;
        }
    }

</style>
